import React from 'react'
import Home from './assets/Home'

function App() {
  return (
    <>
     <Home/>
    </>
  )
}

export default App